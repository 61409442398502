import "./App.css";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ContactUs from "./pages/Contact";
import Home2 from "./pages/Home2";

import Returns from "./pages/ReturnPolicy";
//import RiaBanner from "./components/ria_banner";
//import Banner from "./components/Banner";
//import ria from "./images/ria/Ria.png";

import { Ria2, Hours, OTC, EBT } from "./components/BannerEx";

import BannerSlides from "./components/BannerSlide";

import Nav from "./components/Nav";
function App() {
  return (
    <div className="App">
      <Nav />
      <BannerSlides />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/returns" element={<Returns />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
