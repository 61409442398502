import Banner from "./Banner";
import ria from "../images/logos/Ria.png";
import hf from "../images/logos/hf.png";
import ebt from "../images/logos/ebt.png";

export function Ria2() {
  return (
    <Banner
      text="We now have"
      img={ria}
      style={{
        background: "linear-gradient(to right, #FF9655, white, #FF9655)",
      }}
    />
  );
}

export function Hours() {
  return (
    <Banner
      text="Open Daily 7 AM - 8 PM"
      style={{
        background: "linear-gradient(to right, #58a0d6, white, #58a0d6)",
      }}
    />
  );
}

export function OTC() {
  return (
    <Banner
      text="We accept OTC"
      img={hf}
      style={{
        background: "linear-gradient(to right, #6aa84f, white, #6aa84f)",
      }}
    />
  );
}

export function EBT() {
  return (
    <Banner
      text="We accept EBT"
      img={ebt}
      style={{
        background: "linear-gradient(to right, #ea9999, white, #ea9999)",
      }}
    />
  );
}
