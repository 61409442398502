import { Ria2, Hours, OTC, EBT } from "./BannerEx";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/autoplay";
import "swiper/css/effect-creative";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default function BannerSlides() {
  return (
    <>
      <Swiper
        modules={[Autoplay, Pagination, Navigation]}
        autoplay={{ delay: 7000 }}
        slidesPerView={1}
        loop={true}
        navigation={true}
        style={{
          "--swiper-navigation-color": "#404040",
          "--swiper-navigation-size": "15px",
        }}

        // Alternative with 3sec default timeout:
        //autoplay={true}
      >
        <SwiperSlide>
          <Ria2 />
        </SwiperSlide>
        <SwiperSlide>
          <Hours />
        </SwiperSlide>
        <SwiperSlide>
          <OTC />
        </SwiperSlide>
        <SwiperSlide>
          <EBT />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
