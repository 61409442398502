//Home.js

import PdfLink from "../components/PdfLink";

import Slides from "../components/Slides";
import XmasSlides from "../components/superbowl_slides";
import NewSlides from "../components/NewAds";

export default function Home() {
  return (
    <div className="w-full min-h-full flex-col justify-center align-center items-center md:mx-2 my-4 ">
      <div className="pb-16">
        <NewAd />
      </div>
    </div>
  );
}

function Ad() {
  return (
    <div className="flex flex-col lg:flex-row items-center h-auto lg:gap-10 lg:justify-around">
      <div className="flex  text-center gap-3">
        <div></div>
        <div>
          <PdfLink title="This week's sales" Pdf="/flyer.pdf" />
          <p className="pb-2 text-xs md:text-base">
            Friday, December 20 - Thursday, December 26
          </p>
        </div>
      </div>

      <div className=" flex">
        <Slides />
      </div>
    </div>
  );
}

function Xmas1() {
  return (
    <div className=" flex">
      <XmasSlides />
    </div>
  );
}

function NewAd() {
  return (
    <div className="box-border flex flex-col items-center md:flex-row gap-4 p-4  justify-center md:items-start ">
      <div className="w-screen px-2">
        <NewSlides />
      </div>
      <div className="w-screen">
        <XmasSlides />
      </div>
    </div>
  );
}
