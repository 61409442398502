/* import ria from "../images/logos/Ria.png";

import { twMerge } from "tailwind-merge";

function Banner2(props) {
  const className = twMerge(
    " flex items-center gap-2 md:gap-4 max-md:flex-row text-black px-6 py-1.5  font-[sans-serif] justify-center ",
    props.colors
  );
  return (
    <div {...props} className={className}>
      <div className="h-4 md:h-6">
        <p class=" text-sm md:text-base  max-md:text-center align-bottom tracking-wider">
          {props.text}
        </p>
      </div>

      <div className=" md:pb-1.5">
        <img src={props.img} className="h-4 md:h-6" />
      </div>
    </div>
  );
} */

export default function Banner(props) {
  return (
    <div
      {...props}
      className="flex items-center gap-2 md:gap-4 max-md:flex-row text-black px-6 py-1.5  font-[sans-serif] justify-center"
    >
      <div className="h-4 md:h-6">
        <p class=" text-sm md:text-base  max-md:text-center align-bottom tracking-wider">
          {props.text}
        </p>
      </div>

      <div className=" md:pb-1.5">
        <img src={props.img} className="h-4 md:h-6" />
      </div>
    </div>
  );
}
