import * as img from "../images/dec13/idx";
import front from "../images/jan16/front.jpg";
import back from "../images/jan16/back.jpg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

export default function NewSlides() {
  return (
    <>
      <Swiper
        autoHeight={true}
        loop={true}
        style={{
          "--swiper-navigation-color": "#268E56",
          "--swiper-pagination-bottom": " 0px",
          "--swiper-pagination-color": "#268E56",
        }}
        pagination={{
          type: "fraction",
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper flex object-contain h-auto w-fit max-w-xl"
      >
        <SwiperSlide>
          <Slide src={front} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide src={back} />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

function Slide(props) {
  return (
    <div className="flex justify-center h-fit w-fit">
      <img src={props.src} className="h-auto block w-screen object-contain" />
    </div>
  );
}
